<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="3" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Название: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                name="linkName"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Ссылка: </a-col>
            <a-col :span="6" class="sm-mb">
              <a-input
                v-model="form.link"
                type="url"
                name="linkUrl"
                placeholder="Введите ссылку (Обязательно http:// или https:// )"
                :class="{
                  isInvalidUrl:
                    ($v.form.link.$dirty && !$v.form.link.required) ||
                    ($v.form.link.$dirty && !$v.form.link.url)
                }"
              />
              <small
                v-if="$v.form.link.$dirty && !$v.form.link.required"
                class="error-message"
                >Введите название ссылки</small
              >
              <small v-else-if="!$v.form.link.url" class="error-message"
                >Введите корректную ссылку в которой есть http:// или https://
              </small>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="3" class="form_title"> Изображение: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <span v-if="!image_url" style="padding-left: 8px"
                >Картинка не выбрана
              </span>
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFileUpload"
              />
              <div class="committee-image">
                <img :src="image_url" />
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="3" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />Отмена
                </a-button>
                <a-button type="primary" @click="submitUsefullLinks">
                  <a-icon type="check" />Добавить
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import { required, url } from "vuelidate/lib/validators"
export default {
  components: {
    // "image-upload": () => import("@/components/utils/image-upload")
  },
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        link: "",
        type: 2,
        logo: null
      },
      langTab: "oz",
      image: null,
      image_url: ""
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async submitUsefullLinks() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      let f = new FormData()
      Object.keys(this.form).forEach((key) => {
        f !== "logo" && f.append(key, this.form[key])
        // f === "logo" && f.append(key, this.form[key], this.form[key].name)
      })
      try {
        await this.$api.post("/admin/common/usefullink/create/", f)
        this.$message.success("Полезная ссылка создана успешно")
        this.$router.push({ name: "usefullLinks" })
      } catch (e) {
        console.log(e)
      }
    },

    handleImages(arg) {
      console.log(arg)
      this.form.photos = arg
    },

    handleFileUpload(e) {
      // this.form.photo = this.$refs.file.files[0]
      console.log(e.target.files[0])
      this.image_url = URL.createObjectURL(e.target.files[0])
      this.form.logo = e.target.files[0]
    }
  },
  validations: {
    form: {
      link: {
        required,
        url
      }
    }
  }
}
</script>

<style scoped>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
.isInvalidUrl {
  border: 1px solid red;
  box-shadow: 0 0 3px red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
